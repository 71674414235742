const conditions = [{
  title: "최종 제출 여부",
  name: "finished",
  items: [{
    title: "예",
    value: true,
  }, {
    title: "아니오",
    value: false,
  }]
}, {
  title: "취소 여부",
  name: "canceled",
  items: [{
    title: "예",
    value: true,
  }, {
    title: "아니오",
    value: false,
  }]
}, {
  title: "진행 현황",
  name: "status",
  items: [{
    title: "심사 전",
    value: "unproven",
  }, {
    title: "심사 중",
    value: "judge",
  }, {
    title: "통과",
    value: "pass",
  }, {
    title: "부적합",
    value: "denied",
  }, {
    title: "포기",
    value: "withdrawn",
  }, {
    title: "보류",
    value: "pending",
  }]
},];

export default {conditions};